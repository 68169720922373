
export default {

    conversionRates(state){
        return state.conversionRates
    },

    conversionRate(state){
        return state.conversionRate
    }
}