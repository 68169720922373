
export default {

    conversionRates(state,data){
         state.conversionRates = data
    },

    conversionRate(state,data){
         state.conversionRate = data
    }
}