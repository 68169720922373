
export default {

    productServices(state){
        return state.productServices
    },

    productService(state){
         return state.productService
    }
}