import endPoints from "./endpoints"
import {http} from './axios-config'

export default {
    verifyPassport(data){
        return http().post(`${endPoints.verification}/passport`,data)
    },

    verifyBVN(data){
        return http().post(`${endPoints.verification}/bvn`,data)
    },

    verifyNIN(data){
        return http().post(`${endPoints.verification}/nin`,data)
    },
}