
export default {

    charges(state){
        return state.charges
    },

    charge(state){
         return state.charge
    }
}