
export default {

    productServices(state,data){
         state.productServices = data
    },

    productService(state,data){
         state.productService = data
    }
}