import actions from '../store/actions/payment-actions'


export default{
    namespaced:true,
    state(){
        return{
            
        }
    },
    actions:{
        ...actions
    },
    getters:{
        //...getters
    },
    mutations:{
        //...mutations
    }
}