
export default {

    serviceProviders(state){
        return state.serviceProviders
    },

    serviceProvider(state){
         return state.serviceProvider
    }
}