
export default {

    charges(state,data){
         state.charges = data
    },

    charge(state,data){
          state.charge = data
    }
}